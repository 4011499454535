import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
} from "@mui/material";
// cmp
import VaccumModesPicker from "../dialog-selectors/VaccumModesPicker";
import SuctionModesPicker from "../dialog-selectors/SuctionModesPicker";
//import DirectionControlComponent from "../dialog-selectors/direction-control-component";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
import useNumberFormatter from "../../hooks/useNumberFormatter";
import useStorage, { StorageKeys } from "../../hooks/useStorage";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { decimal2Hex } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendGeneralCmdWrite } from "../../types/message";
import type { RelativePathPrefix/*, ValueOf*/ } from "../../types/misc";

type VaccumModeValue = typeof Constants.VaccumModes[number]["value"];
type SuctionModeValue = typeof Constants.SuctionModes[number]["value"];
// type DirectionControlCmdPayload = ValueOf<typeof ClusterConstants.DFF97.DirectionControlCmdPayloads>;

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"FF97">;
	relativePathPrefix: RelativePathPrefix;
	showFull?: boolean;
};

const DFF97 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const numberFormatter0Digits = useNumberFormatter(0);

	const [vaccumFullMenuView] = useStorage(StorageKeys.vaccumFullMenuView);

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	const charge = cluster?.[ClusterConstants.DFF97.Attributes.Charge] ?? null;

	const [showVaccumModesPicker, setShowVaccumModesPicker] = useState(false);
	const [showSuctionModesPicker, setShowSuctionModesPicker] = useState(false);
	// const [showDirectionControlDialog, setShowDirectionControlDialog] = useState(false);

	const [vaccumModeValue, setVaccumModeValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF97.Attributes.VaccumMode] ?? null);
	const [suctionModeValue, setSuctionModeValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF97.Attributes.SuctionMode] ?? null);
	// const [directionValue, setDirectionValue] = useDynamicUpdateState(cluster[ClusterConstants.DFF97.Attributes.DirectionControl] ?? null);

	const handleVaccumModeChange = (vaccumMode: VaccumModeValue) => {
		if (vaccumMode === vaccumModeValue) {
			setShowVaccumModesPicker(false);
		} else {
			const cmd = {
				action: "sendGeneralCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				// caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: Constants.GeneralCmdIds.WriteAttribute,
				values: [{
					id: ClusterConstants.DFF97.Attributes.VaccumMode,
					// datatype: Constants.DataType.???, // TODO
					value: decimal2Hex(vaccumMode, 2),
				}],
			} as const satisfies CmdSendGeneralCmdWrite<"FF97">;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setVaccumModeValue(vaccumMode);
				}
				setShowVaccumModesPicker(false);
			});
		}
	};

	const handleSuctionModeChange = (suctionMode: SuctionModeValue) => {
		if (suctionMode === suctionModeValue) {
			setShowSuctionModesPicker(false);
		} else {
			const cmd = {
				action: "sendGeneralCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				// caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: Constants.GeneralCmdIds.WriteAttribute,
				values: [{
					id: ClusterConstants.DFF97.Attributes.SuctionMode,
					// datatype: Constants.DataType.???, // TODO
					value: decimal2Hex(suctionMode, 2),
				}],
			} as const satisfies CmdSendGeneralCmdWrite<"FF97">;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setSuctionModeValue(suctionMode);
				}
				setShowSuctionModesPicker(false);
			});
		}
	};

	// handleDirectionCmds = (directionCmd: DirectionControlCmdPayload) => {
	// 	const cmd = {
	// 		action: "sendGeneralCmd",
	// 		gatewayId: props.epDevice.gwId,
	// 		srcGw: props.epDevice.srcGw,
	// 		deviceId: props.epDevice.id,
	// 		endpoint: props.epDevice.epId,
	// 		// caps: props.deviceType.cap,
	// 		clusterId: props.deviceType.clusterId,
	// 		cmdId: Constants.GeneralCmdIds.WriteAttribute,
	// 		values: [{
	// 			id: ClusterConstants.DFF97.Attributes.DirectionControl,
	// 			// datatype: Constants.DataType.???, // TODO
	// 			value: decimal2Hex(directionCmd, 2)
	// 		}]
	// 	} as const satisfies CmdSendGeneralCmdWrite<"FF97">;
	// 	send(cmd); // TODO: add Toast if used
	// };

	if (props.showFull) {
		const vaccumMode = Constants.VaccumModes.find((vaccumMode) => (vaccumMode.value === vaccumModeValue));
		const suctionMode = Constants.SuctionModes.find((suctionMode) => (suctionMode.value === suctionModeValue));

		return (
			<>
				{(vaccumModeValue !== null) &&
					<>
						<ListItemButton onClick={() => (setShowVaccumModesPicker(true))}>
							<ListItemText primary={t("clusters.DFF97.mode")} />
							<ListItemText
								primary={t([`clusters.DFF97.vaccumModes.${vaccumMode?.l10n ?? "unknown"}`, "clusters.DFF97.vaccumModes.unknown"] as const)}
								primaryTypographyProps={{ sx: { color: "primary.main" } }}
							/>
						</ListItemButton>
						<VaccumModesPicker
							open={showVaccumModesPicker}
							isFullMenuView={vaccumFullMenuView}
							value={vaccumModeValue}
							onChange={handleVaccumModeChange}
							onCancel={() => (setShowVaccumModesPicker(false))}
						/>
					</>
				}
				{(suctionModeValue !== null) &&
					<>
						<Divider />
						<ListItemButton onClick={() => (setShowSuctionModesPicker(true))}>
							<ListItemText primary={t("clusters.DFF97.suction")} />
							<ListItemText
								primary={t([`clusters.DFF97.suctionModes.${suctionMode?.l10n ?? "unknown"}`, "clusters.DFF97.suctionModes.unknown"] as const)}
								primaryTypographyProps={{ sx: { color: "primary.main" } }}
							/>
						</ListItemButton>
						<SuctionModesPicker
							open={showSuctionModesPicker}
							isFullMenuView={vaccumFullMenuView}
							value={suctionModeValue}
							onChange={handleSuctionModeChange}
							onCancel={() => (setShowSuctionModesPicker(false))}
						/>
					</>
				}
				{/* Direction Control to be used in future
				{(directionValue !== null) &&
					<>
						<Divider />
						<ListItemButton onClick={() => (setShowDirectionControlDialog(true))}>
							<ListItemText primary={t("clusters.DFF97.manualControl")} />
							<ListItemIcon><icons.ChevronRight /></ListItemIcon>
						</ListItemButton>
						<DirectionControlComponent
							open={showDirectionControlDialog}
							value={directionValue}
							onDirectionChange={handleDirectionCmds}
							onClose={() => (setShowDirectionControlDialog(false))}
						/>
					</>
				}
				*/}
				{(charge !== null) &&
					<>
						<Divider />
						<ListItem>
							<ListItemText primary={t("clusters.DFF97.charge")} />
							<ListItemText primary={`${numberFormatter0Digits(charge)} ${Constants.Units.Percent}`} />
						</ListItem>
					</>
				}
				<Divider />
				<ListItemButton component={Link} to={`${props.relativePathPrefix}settings/${Constants.SettingsPageTypes.Vaccum}`}>
					<ListItemText primary={t("clusters.DFF97.advancedSettings")} />
					<ListItemIcon><icons.ChevronRight /></ListItemIcon>
				</ListItemButton>
			</>
		);
	}

	return null;
};

DFF97.defaultProps = {
	showFull: false,
};

DFF97.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
	}).isRequired,
	relativePathPrefix: PropTypes.string.isRequired,
	showFull: PropTypes.bool,
};

export default DFF97;
