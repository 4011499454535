import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, ListItem, ListItemText } from "@mui/material";
// cmp
import ListItemContainer from "../ListItemContainer";
import UnitDisplay from "./UnitDisplay";
import NumberPicker from "../dialog-selectors/NumberPicker";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { decimal2Hex, hex2Decimal } from "../../services/utils";
// types
import type { EpDevice } from "../../types/device";
// import type { IncapsClusterFD00 } from "../../types/cluster";
import type { DeviceType as DeviceTypeT } from "../../types/device-type";
import type { CmdSendGeneralCmdWrite } from "../../types/message";
// import type { RelativePathPrefix } from "../../types/misc";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceTypeT<"FD00">;
	// relativePathPrefix: RelativePathPrefix;
	showFull: boolean;
};

const DFD00 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	// const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const [pulsePerKwh, setPulsePerKwh] = useDynamicUpdateState(cluster?.[ClusterConstants.DFD00.Attributes.PulsPerKwh] ?? 0);

	if (!props.showFull) {
		return null;
	}

	const setNewValue = (event: null, value: number) => {
		setPulsePerKwh(value);
		setShowDialog(false);
		// setLoading(true);

		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.DFD00.Attributes.PulsPerKwh,
				datatype: Constants.DataType.UInt16Bit,
				value: decimal2Hex(value, 4),
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"FD00">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	return (
		<>
			<ListItem>
				<ListItemText primary={t("clusters.DFD00.title")} secondary={t("clusters.DFD00.subtitle")} />
				<ListItemContainer>
					<Button onClick={() => (setShowDialog(true))}>
						<UnitDisplay
							values={[pulsePerKwh]}
							digits={0}
							unit=""
						/>
					</Button>
				</ListItemContainer>
			</ListItem>
			<NumberPicker
				open={showDialog}
				title={t("clusters.DFD00.title")}
				label={t("clusters.DFD00.title")}
				value={pulsePerKwh}
				min={0}
				max={hex2Decimal("FFFF")}
				step={1}
				onChange={setNewValue}
				onCancel={() => (setShowDialog(false))}
			/>
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

DFD00.defaultProps = {
	showFull: false,
};

DFD00.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
	}).isRequired,
	// relativePathPrefix: PropTypes.string.isRequired,
	showFull: PropTypes.bool,
};

export default DFD00;
