import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ListItem, ListItemText } from "@mui/material";
// services
import ClusterConstants from "../../services/cluster-constants";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"FFAE">;
};

const DFFAE = (props: Props) => {
	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	return (
		<ListItem>
			<ListItemText primary={props.deviceType.getTitle()} />
			<ListItemText primary={t(`clusters.DFFAE.notificationType.${cluster?.[ClusterConstants.DFFAE.Attributes.NotificationType] ?? "0"}`)} />
		</ListItem>
	);
};

DFFAE.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		getTitle: PropTypes.func.isRequired,
	}).isRequired,
};

export default DFFAE;
