import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Paper, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
// cmp
import Svg from "../svg";
// hooks
import useDevices from "../../hooks/useDevices";
// services
import Constants from "../../services/constants";
import Devices from "../../services/devices";
import { hasEndpointClusterId } from "../../services/device-helpers";
import { icons } from "@local/theme";
// types
import type { EpDevice, EpDevices } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { RelativePathPrefix } from "../../types/misc";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"0004">;
	relativePathPrefix: RelativePathPrefix;
};

const GroupComponent = (props: Props) => {
	const { t } = useTranslation();
	const { loading, devices } = useDevices();

	const isGroup = props.epDevice.getRocIdData("is_group") ?? false;

	const [selectedEpDevices, setSelectedEpDevices] = useState<EpDevices>([]);

	useEffect(() => {
		if (!loading) {
			let _epDevices: EpDevices = [];
			let _selectedEpDevices: EpDevices = [];
			if (isGroup) {
				_epDevices = Devices.getEpDevicesFromDevices(devices, (endpoint) => (
					hasEndpointClusterId(endpoint, props.deviceType.clusterId)
				));
				_selectedEpDevices = _epDevices.filter((_epDevice) => {
					const cluster = _epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
					return cluster?.groups.includes(props.epDevice.id);
				});
			} else {
				_epDevices = Devices.getEpDevicesFromDevices(devices, (_endpoint, device) => (
					Boolean(device.getRocIdData("is_group")) // && hasEndpointClusterId(endpoint, props.deviceType.clusterId);
				));
				const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
				if (cluster?.groups) {
					_selectedEpDevices = _epDevices.filter((_epDevice) => (cluster.groups.includes(_epDevice.id)));
				}
			}

			// selectedEpDevices gets updated by reference
			for (const _epDevice of _epDevices) {
				_epDevice.epDeviceType = Constants.EpDeviceType.Endpoint;
			}

			setSelectedEpDevices(_selectedEpDevices);
		}
	}, [isGroup, props.deviceType.cap, props.deviceType.clusterId, loading, devices]);

	const groupsOrDevicesType = isGroup ? "groups" : "devices";

	return (
		<Paper sx={{ margin: "5px 0" }}>
			<ListItemButton
				className="li-device-ep-groups"
				component={Link}
				to={`${props.relativePathPrefix}groups`}
			>
				<ListItemIcon><Svg src="device-details/groups.svg" /></ListItemIcon>
				<ListItemText primary={t(`clusters.D0004.${groupsOrDevicesType}.title`)} secondary={t(`clusters.D0004.${groupsOrDevicesType}.titleSub`)} />
				{(selectedEpDevices.length > 0) &&
					<ListItemText primaryTypographyProps={{ sx: { fontSize: "1.3em" } }} sx={{ flexShrink: 0 }}>{selectedEpDevices.length}</ListItemText>
				}
				<ListItemIcon><icons.ChevronRight /></ListItemIcon>
			</ListItemButton>
		</Paper>
	);
};

GroupComponent.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
	}).isRequired,
	relativePathPrefix: PropTypes.string.isRequired,
};

export default GroupComponent;
