import { Component } from "react";
import PropTypes from "prop-types";
// services
import { getTimePartsFromDuration } from "../../services/utils";
// types
import type { CSSProperties } from "react";
import type { IsoTimeStamp } from "../../types/misc";

type Props = {
	dateTime: IsoTimeStamp;
	hideHours: boolean;
	onTimerCompleted: (() => void) | undefined;
	style: CSSProperties;
};

type State = {
	timeDiff: number;
};

class Timer extends Component<Props, State> {

	#intervalId: number | undefined = undefined;

	constructor(props: Props) {
		super(props);

		const timeDiff = Timer.getTimeDiff(props.dateTime);

		this.state = {
			timeDiff: timeDiff,
		};

		this.startTimer(timeDiff);
	}

	override componentDidUpdate(prevProps: Props) {
		if (prevProps.dateTime !== this.props.dateTime) {
			const timeDiff = Timer.getTimeDiff(this.props.dateTime);
			this.setState({
				timeDiff: timeDiff,
			});

			this.startTimer(timeDiff);
		}
	}

	override componentWillUnmount() {
		window.clearInterval(this.#intervalId);
	}

	static getTimeDiff(time: string) {
		const timeObj = new Date(time);
		return Math.max(0, timeObj.getTime() - Date.now());
	}

	startTimer(timeDiff: number) {
		window.clearInterval(this.#intervalId);

		if (timeDiff > 0) {
			this.#intervalId = window.setInterval(() => {
				const timeDiff = Timer.getTimeDiff(this.props.dateTime);
				this.setState({
					timeDiff: timeDiff,
				});
				if (timeDiff === 0 && this.props.onTimerCompleted) {
					this.props.onTimerCompleted();
				}
			}, 1000);
		}
	}

	getFormatedTime(timeDiff: number) {
		const { hours, minutes, seconds } = getTimePartsFromDuration(timeDiff);
		return [hours, minutes, seconds].filter((digit, index) => (!this.props.hideHours || index > 0)).map((digit) => (digit.toString().padStart(2, "0"))).join(":");
	}

	override render() {
		if (this.state.timeDiff > 0) {
			return (
				<span style={this.props.style}>
					{this.getFormatedTime(this.state.timeDiff)}
				</span>
			);
		}

		return null;
	}

}

Timer.defaultProps = {
	hideHours: false,
	onTimerCompleted: undefined,
	style: {},
};

Timer.propTypes = {
	dateTime: PropTypes.string.isRequired,
	hideHours: PropTypes.bool,
	onTimerCompleted: PropTypes.func,
	style: PropTypes.object,
};

export default Timer;
