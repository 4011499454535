import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	Divider,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
// cmp
import ListItemContainer from "../ListItemContainer";
import CustomSwitch from "../custom-switch";
import Svg from "../svg";
import PetFeeder from "../dialog-selectors/PetFeeder";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { decimal2Hex } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendGeneralCmdWrite } from "../../types/message";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"FF96">;
	showFull?: boolean;
};

const DFF96 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	const foodNumber = cluster?.[ClusterConstants.DFF96.Attributes.FoodNumber] ?? null;
	const foodWeight = cluster?.[ClusterConstants.DFF96.Attributes.FoodWeight] ?? Constants.FoodWeightDefaultValue;

	const [showFeederDialog, setShowFeederDialog] = useState(false);

	const [toggleWaterUV, setToggleWaterUV] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF96.Attributes.WaterUV] ?? null);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const sendFeedCmd = (value: number) => {
		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			// caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.DFF96.Attributes.FoodNumber,
				// datatype: Constants.DataType.???, // TODO
				value: decimal2Hex(value, 4),
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"FF96">;
		send(cmd, (error, msg) => {
			setShowFeederDialog(false);
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleToggle = (isInputChecked: boolean) => {
		setToggleWaterUV(isInputChecked);

		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			// caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.DFF96.Attributes.WaterUV,
				// datatype: Constants.DataType.???, // TODO
				value: isInputChecked,
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"FF96">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	if (props.showFull) {
		return (
			<>
				{(toggleWaterUV !== null) &&
					<>
						<Divider />
						<ListItem>
							<ListItemText primary={t("clusters.DFF96.title")} />
							<ListItemContainer><CustomSwitch checked={toggleWaterUV} onChange={handleToggle} /></ListItemContainer>
						</ListItem>
					</>
				}
				{(foodNumber !== null) &&
					<>
						<ListItemButton onClick={() => (setShowFeederDialog(true))} sx={{ height: "72px" }}>
							<ListItemIcon><Svg src="device-details/pet_feeder.svg" size="medium" /></ListItemIcon>
							<ListItemText primary={t("clusters.DFF96.manualFeed")} />
							<ListItemIcon><icons.ChevronRight /></ListItemIcon>
						</ListItemButton>
						<PetFeeder
							open={showFeederDialog}
							value={foodNumber}
							portionWeight={foodWeight}
							onFoodNumberChange={sendFeedCmd}
							onClose={() => (setShowFeederDialog(false))}
						/>
					</>
				}
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

	return null;
};

DFF96.defaultProps = {
	showFull: false,
};

DFF96.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
	}).isRequired,
	showFull: PropTypes.bool,
};

export default DFF96;
