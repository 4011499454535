import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	Divider,
	ListItem,
	ListItemText,
	Button,
} from "@mui/material";
// cmp
import CustomSwitch from "../custom-switch";
import ListItemContainer from "../ListItemContainer";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import ClusterConstants from "../../services/cluster-constants";
import Constants from "../../services/constants";
import { START_SWITCH_OPERATION_STATE_ENABLED, STOP_SWITCH_OPERATION_STATE_ENABLED } from "../../services/utils";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendActionCmd } from "../../types/message";

const ALLOWED_DOORLOCK_STATES = [1, 2] as const; // Locked and Closed
const ACTIVE_POWER_STATES = [1, 2] as const; // On and Standby

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"FFAC">;
	showFull?: boolean;
};

const DFFAC = (props: Props) => {
	const send = useSend();
	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	const operationState = cluster?.[ClusterConstants.DFFAC.Attributes.OperationState];
	const operationStateValue = Constants.HomeConnect.AvailableOperationState.find((operation) => (operation.value === operationState));
	const remoteControlState = Boolean(cluster?.[ClusterConstants.DFFAC.Attributes.RemoteControlActivationState]);
	const remoteControlStartState = Boolean(cluster?.[ClusterConstants.DFFAC.Attributes.RemoteControlAllowanceState]);
	const isPowerStateSupported = cluster && (
		Boolean(cluster[ClusterConstants.DFFAC.Attributes.PowerState] & Constants.HomeConnect.SupportedOperationPowerState.Off) ||
		Boolean(cluster[ClusterConstants.DFFAC.Attributes.PowerState] & Constants.HomeConnect.SupportedOperationPowerState.Standby)
	) && Boolean(cluster[ClusterConstants.DFFAC.Attributes.PowerState] & Constants.HomeConnect.SupportedOperationPowerState.On);
	const doorState = Constants.HomeConnect.DoorLockStates.find((doorLockState) => (doorLockState.value === cluster?.[ClusterConstants.DFFAC.Attributes.DoorState]));

	const [showError, setShowError] = useState<number | undefined>(undefined);
	const [toggle, setToggle] = useDynamicUpdateState(ACTIVE_POWER_STATES.includes(cluster?.[ClusterConstants.DFFAC.Attributes.CurrentPowerState] ?? 0));

	const handleToggle = (isInputChecked: boolean) => {
		setToggle(isInputChecked);
		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.DFFAC.CmdIds.ChangePowerState,
			value: isInputChecked ? ClusterConstants.DFFAC.CmdPayloads.TurnOnDevice : ClusterConstants.DFFAC.CmdPayloads.TurnOffDevice,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowError(undefined);
			} else {
				setShowError(Date.now());
			}
		});
	};

	const handleStartProgram = () => {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.DFFAC.CmdIds.StartProgram,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowError(undefined);
			} else {
				setShowError(Date.now());
			}
		});
	};

	const handleStopProgram = () => {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.DFFAC.CmdIds.StopProgram,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowError(undefined);
			} else {
				setShowError(Date.now());
			}
		});
	};

	if (props.showFull) {
		return (
			<>
				{isPowerStateSupported &&
					<>
						<ListItem>
							<ListItemText primary={t(toggle ? "clusters.DFFAC.on" : "clusters.DFFAC.off")} />
							<ListItemContainer>
								<CustomSwitch
									className="switch-FFAC"
									checked={toggle}
									onChange={handleToggle}
								/>
							</ListItemContainer>
						</ListItem>
						<Divider />
					</>
				}
				<ListItem>
					<ListItemText primary={t("clusters.DFFAC.startStop")} />
					<ListItemContainer>
						<Button
							variant="contained"
							disabled={!(START_SWITCH_OPERATION_STATE_ENABLED.includes(operationState) && remoteControlState && remoteControlStartState && ALLOWED_DOORLOCK_STATES.includes(doorState?.value))}
							onClick={handleStartProgram}
						>
							{t("clusters.DFFAC.start")}
						</Button>
						<Button
							variant="contained"
							disabled={!(STOP_SWITCH_OPERATION_STATE_ENABLED.includes(operationState) && remoteControlState && remoteControlStartState && ALLOWED_DOORLOCK_STATES.includes(doorState?.value))}
							onClick={handleStopProgram}
							sx={{ ml: "8px" }}
						>
							{t("clusters.DFFAC.stop")}
						</Button>
					</ListItemContainer>
				</ListItem>
				<Divider />
				<ListItem>
					<ListItemText primary={t("clusters.DFFAC.currentProgram")} />
					<ListItemText primary={cluster?.[ClusterConstants.DFFAC.Attributes.LocalizedProgramName] ?? ""} />
				</ListItem>
				<Divider />
				<ListItem>
					<ListItemText primary={t("clusters.DFFAC.notification")} />
					<ListItemText primary={(operationStateValue === undefined) ? "" : t(operationStateValue.l10n)} />
				</ListItem>
				<Divider />
				<ListItem>
					<ListItemText primary={t("clusters.DFFAC.doorState")} />
					<ListItemText primary={(doorState === undefined) ? "" : t(doorState.l10n)} />
				</ListItem>
				<Divider />
				<ListItem>
					<ListItemText primary={t("clusters.DFFAC.remoteStart")} />
					<ListItemText primary={t((remoteControlState && remoteControlStartState) ? "clusters.DFFAC.enabled" : "clusters.DFFAC.disabled")} />
				</ListItem>
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showError}
					onClose={setShowError}
					message={t("clusters.DFFAC.errorMsg")}
				/>
			</>
		);
	}

	return null;
};

DFFAC.defaultProps = {
	showFull: false,
};

DFFAC.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
	}).isRequired,
	showFull: PropTypes.bool,
};

export default DFFAC;
