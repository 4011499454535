import PropTypes from "prop-types";
import { ListItem, ListItemText } from "@mui/material";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"FD05">;
	showFull?: boolean;
};

const DFD05 = (props: Props) => {
	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId)!;

	if (props.showFull) {
		return (
			<ListItem>
				<ListItemText sx={{ minWidth: "fit-content" }} primary={props.deviceType.getTitle()} />
				<ListItemText sx={{ paddingLeft: "16px" }}>{props.deviceType.formatValue(cluster)}</ListItemText>
			</ListItem>
		);
	}
	return null;
};

DFD05.defaultProps = {
	showFull: false,
};

DFD05.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		getTitle: PropTypes.func.isRequired,
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		formatValue: PropTypes.func.isRequired,
	}).isRequired,
	showFull: PropTypes.bool,
};

export default DFD05;
