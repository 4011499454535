import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	CircularProgress,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	DialogContentText,
	Button,
	Divider,
} from "@mui/material";
// cmp
import DrawerDialog from "../DrawerDialog";
import ListItemContainer from "../ListItemContainer";
import Timer from "./timer";
import PasswordCopy from "../password-copy";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import ClusterConstants from "../../services/cluster-constants";
import Constants from "../../services/constants";
import { icons } from "@local/theme";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType as DeviceTypeT } from "../../types/device-type";
import type { CmdSendActionCmd } from "../../types/message";
import type { RelativePathPrefix } from "../../types/misc";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceTypeT<"FF9B">;
	relativePathPrefix: RelativePathPrefix;
	showFull: boolean;
};

const DFF9B = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
	const doorLockCapability = cluster?.[ClusterConstants.DFF9B.Attributes.DoorLockCapabilities] ?? 3;
	const _isUnlockRequested = Boolean(cluster?.[ClusterConstants.DFF9B.Attributes.RequestToUnlock]);
	const unlockRequestExpiry = cluster?.[ClusterConstants.DFF9B.Attributes.RequestToUnlockExpiry];

	const [loading, setLoading] = useState(true);
	const [showDialog, setShowDialog] = useState(false);
	const [error, setError] = useState(false);
	const [remoteUnlockError, setRemoteUnlockError] = useState(false);
	const [dynamicPassword, setDynamicPassword] = useState("");
	const timeoutIdRef = useRef<number | undefined>(undefined);
	const [showRemoteUnlock, setShowRemoteUnlock] = useDynamicUpdateState(_isUnlockRequested);
	const [isUnlockRequested, setIsUnlockRequested] = useDynamicUpdateState(_isUnlockRequested);

	useEffect(() => {
		if (unlockRequestExpiry) {
			const timeToExpire = new Date(unlockRequestExpiry).getTime() - Date.now();
			window.clearTimeout(timeoutIdRef.current);
			timeoutIdRef.current = window.setTimeout(() => {
				setIsUnlockRequested(false);
			}, timeToExpire);
		}

		return () => {
			window.clearTimeout(timeoutIdRef.current);
		};
	}, [unlockRequestExpiry]);

	if (!props.showFull) {
		return null;
	}

	const createDynamicPassword = () => {
		setLoading(true);
		setShowDialog(true);
		setError(false);
		setDynamicPassword("");

		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.DFF9B.CmdIds.GenerateDynamicPassword,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			setLoading(false);
			if (!error && msg?.payload.status === "ok") {
				setDynamicPassword(msg.payload.data.dynamic_password);
			} else {
				setError(true);
			}
		});
	};

	const respondToUnlockRequest = () => {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.DFF9B.CmdIds.RemoteUnlock,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (error && msg.payload.status !== "ok") {
				setRemoteUnlockError(true);
			}
		});
	};

	return (
		<>
			{(Boolean(doorLockCapability & Constants.DoorLockCapabilities.TemporaryPasswords) || Boolean(doorLockCapability & Constants.DoorLockCapabilities.DynamicPasswords)) &&
				<>
					<ListItem>
						<ListItemText
							primary={t("clusters.DFF9B.title")}
							secondary={t("clusters.DFF9B.subtitle")}
						/>
					</ListItem>
					<Divider />
				</>
			}
			{Boolean(doorLockCapability & Constants.DoorLockCapabilities.TemporaryPasswords) &&
				<>
					<ListItemButton component={Link} to={`${props.relativePathPrefix}guestPassword`}>
						<ListItemText primary={t("clusters.DFF9B.guestPwd")} />
						<ListItemIcon><icons.ChevronRight /></ListItemIcon>
					</ListItemButton>
					<Divider />
				</>
			}
			{Boolean(doorLockCapability & Constants.DoorLockCapabilities.DynamicPasswords) &&
				<>
					<ListItem>
						<ListItemText
							primary={t("clusters.DFF9B.dynamicPwd")}
							secondary={t("clusters.DFF9B.dynamicPwdHint")}
						/>
						<ListItemContainer>
							<Button id="btn-create-dynamic-password" onClick={createDynamicPassword}>
								{t("clusters.DFF9B.create")}
							</Button>
						</ListItemContainer>
					</ListItem>
					<Divider />
				</>
			}
			{isUnlockRequested &&
				<>
					<ListItemButton onClick={() => (setShowRemoteUnlock(true))}>
						<ListItemText
							primary={t("clusters.DFF9B.remoteUnlock.title")}
							secondary={t("clusters.DFF9B.remoteUnlock.subTitle")}
						/>
						{unlockRequestExpiry ? <Timer dateTime={unlockRequestExpiry} /> : ""}
						<ListItemIcon><icons.ChevronRight /></ListItemIcon>
					</ListItemButton>
					<Divider />
				</>
			}
			{Boolean(doorLockCapability & Constants.DoorLockCapabilities.OfflinePasswords) &&
				<>
					<ListItem>
						<ListItemText
							primary={t("clusters.DFF9B.offlinePwds.title")}
							secondary={t("clusters.DFF9B.offlinePwds.subtitle")}
						/>
					</ListItem>
					<Divider />
					<ListItemButton component={Link} to={`${props.relativePathPrefix}oneTimeCode`}>
						<ListItemText primary={t("clusters.DFF9B.offlinePwds.oneTime")} />
						<ListItemIcon><icons.ChevronRight /></ListItemIcon>
					</ListItemButton>
					<Divider />
					<ListItemButton component={Link} to={`${props.relativePathPrefix}timeLimtitedPassword`}>
						<ListItemText primary={t("clusters.DFF9B.offlinePwds.multiTime")} />
						<ListItemIcon><icons.ChevronRight /></ListItemIcon>
					</ListItemButton>
				</>
			}
			<DrawerDialog
				id="dlg-dynamic-password"
				title={t("clusters.DFF9B.dialogTitle")}
				open={showDialog}
				onClose={() => (setShowDialog(false))}
				drawerActions={
					<PasswordCopy
						password={dynamicPassword}
						copyMsg={t("clusters.DFF9B.copyMessage")}
						buttonProps={{
							className: "btn-drawer-action-copy",
							variant: "contained",
							sx: { minWidth: "40%", maxWidth: "320px" },
						}}
					/>
				}
				dialogActions={
					<>
						<Button className="btn-dlg-action-close" color="inherit" onClick={() => (setShowDialog(false))}>{t("dialog.close")}</Button>
						<PasswordCopy
							password={dynamicPassword}
							copyMsg={t("clusters.DFF9B.copyMessage")}
							buttonProps={{
								className: "btn-dlg-action-copy",
								variant: "contained",
								disableElevation: true,
							}}
						/>
					</>
				}
			>
				<div style={{ display: "flex", justifyContent: "center" }}>
					{loading && <CircularProgress />}
					{dynamicPassword &&
						<Typography variant="h5" gutterBottom={true} sx={{ fontSize: "1.3rem" }}>
							{dynamicPassword}
						</Typography>
					}
					{error &&
						<DialogContentText>
							{t("clusters.DFF9B.guestPwdError")}
						</DialogContentText>
					}
				</div>
			</DrawerDialog>
			<DrawerDialog
				id="dlg-remote-unlock"
				title={t("clusters.DFF9B.remoteUnlock.dialogTitle")}
				open={showRemoteUnlock}
				onClose={() => (setShowRemoteUnlock(false))}
				drawerActions={remoteUnlockError ? null :
					<Button
						className="btn-dlg-accept-unlock-request"
						variant="contained"
						onClick={respondToUnlockRequest}
						sx={{ minWidth: "40%", maxWidth: "320px" }}
					>
						{t("clusters.DFF9B.remoteUnlock.accept")}
					</Button>
				}
				dialogActions={
					<>
						<Button className="btn-dlg-action-close" color="inherit" onClick={() => (setShowRemoteUnlock(false))}>
							{t(remoteUnlockError ? "dialog.close" : "clusters.DFF9B.remoteUnlock.reject")}
						</Button>
						{!remoteUnlockError &&
							<Button
								className="btn-dlg-accept-unlock-request"
								variant="contained"
								disableElevation={true}
								onClick={respondToUnlockRequest}
							>
								{t("clusters.DFF9B.remoteUnlock.accept")}
							</Button>
						}
					</>
				}
			>
				{t(remoteUnlockError ? "clusters.DFF9B.remoteUnlock.errorMsg" : "clusters.DFF9B.remoteUnlock.dialogMsg")}
				<Typography>{unlockRequestExpiry ? <Timer dateTime={unlockRequestExpiry} /> : ""}</Typography>
			</DrawerDialog>
		</>
	);
};

DFF9B.defaultProps = {
	showFull: false,
};

DFF9B.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
	}).isRequired,
	relativePathPrefix: PropTypes.string.isRequired,
	showFull: PropTypes.bool,
};

export default DFF9B;
