import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import {
	Divider,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Alert,
} from "@mui/material";
// cmp
import PhoneLocationSettings from "./PhoneLocationSettings";
import Svg from "../svg";
// hooks
import useDeviceInfo from "../../hooks/useDeviceInfo";
// contexts
import { useGateway } from "../../context/GatewayContext";
// services
import ClusterConstants from "../../services/cluster-constants";
import Constants from "../../services/constants";
import { icons } from "@local/theme";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { RelativePathPrefix } from "../../types/misc";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"FFAD">;
	relativePathPrefix: RelativePathPrefix;
	showFull?: boolean;
};

const DFFAD = (props: Props) => {
	const { t } = useTranslation();

	const gateway = useGateway();
	const { loading, deviceId } = useDeviceInfo();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
	const currentStatus = Constants.PhoneStatus.find((phoneSatus) => (phoneSatus.value === cluster?.[ClusterConstants.DFFAD.Attributes.PhoneStatus]));
	const currentMode = Constants.PhoneModes.find((phoneMode) => (phoneMode.value === cluster?.[ClusterConstants.DFFAD.Attributes.PhoneMode]));
	const trackLocationEnabled = cluster?.[ClusterConstants.DFFAD.Attributes.TrackLocation] ?? false;
	const [showSetPhoneLocationDialog, setShowSetPhoneLocationDialog] = useState(false);

	if (props.showFull) {
		const isOwner = gateway ? Boolean(gateway.rbac & Constants.Gateway.RBAC.Owner) : false;
		const geofenceDisabled = !gateway?.geofence_status;

		return (
			<>
				{geofenceDisabled &&
					<Alert severity="info" sx={{ margin: "16px 16px 0" }}>
						{t(isOwner ? "clusters.DFFAD.enableGeofenceHintOwner" : "clusters.DFFAD.enableGeofenceHintUser")}
					</Alert>
				}
				<ListItemButton disabled={geofenceDisabled} onClick={() => (setShowSetPhoneLocationDialog(true))}>
					<ListItemIcon><icons.Location /></ListItemIcon>
					<ListItemText primary={t("clusters.DFFAD.phoneModeTitle")} />
					<ListItemText
						primary={currentMode?.label ? t(currentMode.label) : ""}
						primaryTypographyProps={{ sx: { color: "primary.main" } }}
					/>
				</ListItemButton>
				<Divider />
				<ListItem>
					<ListItemIcon><Svg src="device-details/info.svg" /></ListItemIcon>
					<ListItemText primary={props.deviceType.getTitle()} />
					<ListItemText primary={trackLocationEnabled ? (currentStatus ? t(currentStatus.label) : "") : t("clusters.DFFAD.phoneStatusOff")} />
				</ListItem>
				{(Capacitor.isNativePlatform() && !loading && deviceId === props.epDevice.id) &&
					<>
						<Divider />
						<ListItemButton component={Link} to={`${props.relativePathPrefix}settings/${Constants.SettingsPageTypes.Geofence}`}>
							<ListItemIcon><Svg src="navigation/settings.svg" /></ListItemIcon>
							<ListItemText primary={t("clusters.DFFAD.advancedSettings")} />
							<ListItemIcon><icons.ChevronRight /></ListItemIcon>
						</ListItemButton>
					</>
				}
				<PhoneLocationSettings
					open={showSetPhoneLocationDialog}
					epDevice={props.epDevice}
					phoneMode={currentMode?.value}
					trackLocationEnabled={trackLocationEnabled}
					onClose={() => (setShowSetPhoneLocationDialog(false))}
				/>
			</>
		);
	}
	return null;
};

DFFAD.defaultProps = {
	showFull: false,
};

DFFAD.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		getTitle: PropTypes.func.isRequired,
	}).isRequired,
	relativePathPrefix: PropTypes.string.isRequired,
	showFull: PropTypes.bool,
};

export default DFFAD;
