import PropTypes from "prop-types";
import { ListItem, ListItemText, Divider } from "@mui/material";
// services
import ClusterConstants from "../../services/cluster-constants";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"FF9E">;
	showFull?: boolean;
};

const DFF9E = (props: Props) => {
	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId)!;

	if (props.showFull) {
		return (
			<>
				<ListItem>
					<ListItemText primary={props.deviceType.entries[ClusterConstants.DFF9E.Attributes.SDCardStatus].getTitle()} />
					<ListItemText primary={props.deviceType.entries[ClusterConstants.DFF9E.Attributes.SDCardStatus].formatValue(cluster)} />
				</ListItem>
				<Divider />
				<ListItem>
					<ListItemText primary={props.deviceType.entries[ClusterConstants.DFF9E.Attributes.PowerAdapterStatus].getTitle()} />
					<ListItemText primary={props.deviceType.entries[ClusterConstants.DFF9E.Attributes.PowerAdapterStatus].formatValue(cluster)} />
				</ListItem>
			</>
		);
	}
	return null;
};

DFF9E.defaultProps = {
	showFull: false,
};

DFF9E.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		entries: PropTypes.shape({
			[ClusterConstants.DFF9E.Attributes.SDCardStatus]: PropTypes.shape({
				getTitle: PropTypes.func.isRequired,
				formatValue: PropTypes.func.isRequired,
			}).isRequired,
			[ClusterConstants.DFF9E.Attributes.PowerAdapterStatus]: PropTypes.shape({
				getTitle: PropTypes.func.isRequired,
				formatValue: PropTypes.func.isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
	showFull: PropTypes.bool,
};

export default DFF9E;
