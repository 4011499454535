import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	Paper,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	ListItemIcon,
	Button,
} from "@mui/material";
// cmp
import DrawerDialog from "../DrawerDialog";
import ListItemContainer from "../ListItemContainer";
import CustomSwitch from "../custom-switch";
import Toast from "../Toast";
// contexts
import { useGateway } from "../../context/GatewayContext";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import DeviceType from "../../services/device-type";
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { decimal2Hex } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { EpDevice } from "../../types/device";
import type { IncapsClusterFFAD } from "../../types/cluster";
import type { CmdSendActionCmd, CmdGatewayActionGeofenceDisableEnablePhone } from "../../types/message";

type Props = {
	open: boolean;
	epDevice: EpDevice;
	phoneMode: IncapsClusterFFAD[typeof ClusterConstants.DFFAD.Attributes.PhoneMode] | undefined;
	trackLocationEnabled: boolean;
	onClose: () => void;
};

const PhoneLocationSettings = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const gateway = useGateway();

	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);
	const [trackLocationEnabled, setTrackLocationEnabled] = useDynamicUpdateState(props.trackLocationEnabled);

	const handleTrackLocationToggle = (isInputChecked: boolean) => {
		setTrackLocationEnabled(isInputChecked);

		const cmd = {
			action: "gatewayAction",
			module: "geofence",
			function: isInputChecked ? "enable_phone" : "disable_phone",
			params: [{
				id: props.epDevice.id,
			}],
			gatewayId: gateway?.id!,
		} as const satisfies CmdGatewayActionGeofenceDisableEnablePhone;
		send(cmd, (error, msg) => {
			if (error && msg?.payload.status !== "ok") {
				setTrackLocationEnabled((prevTrackLocationEnabled) => (!prevTrackLocationEnabled));
			}
		});
	};

	const handlePhoneModeChange = (phoneMode: number) => {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: DeviceType.DFFAD.cap,
			clusterId: DeviceType.DFFAD.clusterId,
			cmdId: ClusterConstants.DFFAD.CmdIds.ChangePhoneMode,
			value: decimal2Hex(phoneMode, 2),
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	return (
		<>
			<DrawerDialog
				id="dlg-phone-location-mode"
				title={t("clusters.DFFAD.dialogTitle")}
				open={props.open}
				onClose={props.onClose}
				drawerActions={null}
				dialogActions={<Button className="btn-dlg-action-close" color="inherit" onClick={props.onClose}>{t("dialog.close")}</Button>}
			>
				<Paper className="section">
					<ListItem>
						<ListItemText primary={t("clusters.DFFAD.trackLocation")} />
						<ListItemContainer><CustomSwitch checked={trackLocationEnabled} onChange={handleTrackLocationToggle} /></ListItemContainer>
					</ListItem>
				</Paper>
				<Paper className="section">
					<List disablePadding={true}>
						{Constants.PhoneModes.map((phoneMode, index) => (
							<ListItemButton
								key={phoneMode.value}
								className="li-phone-mode"
								divider={index < Constants.PhoneModes.length - 1}
								disabled={!trackLocationEnabled}
								data-phone-mode={phoneMode.value}
								sx={(phoneMode.value === props.phoneMode) ? { color: "primary.main" } : {}}
								onClick={() => (handlePhoneModeChange(phoneMode.value))}
							>
								<ListItemText primary={t(phoneMode.label)} />
								{(phoneMode.value === props.phoneMode) && <ListItemIcon><icons.CheckCircle color="primary" /></ListItemIcon>}
							</ListItemButton>
						))}
					</List>
				</Paper>
			</DrawerDialog>
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

PhoneLocationSettings.defaultProps = {
	phoneMode: undefined,
};

PhoneLocationSettings.propTypes = {
	open: PropTypes.bool.isRequired,
	epDevice: PropTypes.object.isRequired,
	phoneMode: PropTypes.oneOf([0, 1, 2]),
	trackLocationEnabled: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default PhoneLocationSettings;
