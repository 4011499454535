import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	ButtonGroup,
	Button,
	IconButton,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Slider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import DrawerDialog from "../DrawerDialog";
import ListItemContainer from "../ListItemContainer";
import CustomSwitch from "../custom-switch";
import TemperaturePicker from "../dialog-selectors/TemperaturePicker";
import UnitDisplay from "./UnitDisplay";
import Svg from "../svg";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useIsSmallScreen from "../../hooks/useIsSmallScreen";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import { getSetpointOptions } from "../../services/device-helpers";
import Gateway from "../../services/gateway";
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { roundToPrecision, convertToF, decimal2Hex } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { ReactNode, SyntheticEvent } from "react";
import type { ReadonlyDeep, ValueOf } from "type-fest";
import type { SliderProps } from "@mui/material/Slider";
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendActionCmd, CmdSendGeneralCmdWrite } from "../../types/message";
import type { IncapsClusterFF80 } from "../../types/cluster";

const PowerModes = {
	Low: 0,
	High: 1,
} as const;

const FAN_SPEED_MARKS = [
	{
		value: 1,
		label: "1",
	},
	{
		value: 2,
		label: "2",
	},
	{
		value: 3,
		label: "3",
	},
	{
		value: 4,
		label: "4",
	},
	{
		value: 5,
		label: "5",
	},
	{
		value: 6,
		label: "6",
	},
] as const satisfies ReadonlyDeep<Exclude<SliderProps["marks"], boolean | undefined>>;

type Mode = ValueOf<typeof ClusterConstants.DFF80.CmdPayloads>;
type PowerMode = NonNullable<IncapsClusterFF80[typeof ClusterConstants.DFF80.Attributes.PowerMode]>;
type FanSpeed = NonNullable<IncapsClusterFF80[typeof ClusterConstants.DFF80.Attributes.FanSpeed]>;
type ShakeLevel = NonNullable<IncapsClusterFF80[typeof ClusterConstants.DFF80.Attributes.ShakeLevel]>;

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"FF80">;
	showFull?: boolean;
};

const DFF80 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();
	const theme = useTheme();

	const isSmallScreen = useIsSmallScreen();
	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
	const availableModesBitFlag = cluster?.[ClusterConstants.DFF80.Attributes.AvailableModes] ?? 0;
	const availableModes = Constants.ThermostatModes.filter((mode) => (Boolean(availableModesBitFlag & mode.bitFlag)));
	const isSetpointControllable = cluster?.[ClusterConstants.DFF80.Attributes.SetpointControllable] ?? true;

	const [showModeDialog, setShowModeDialog] = useState(false);
	const [showSetpointDialog, setShowSetpointDialog] = useState(false);

	const [modeValue, setModeValue] = useDynamicUpdateState((typeof cluster?.[ClusterConstants.DFF80.Attributes.CurrentMode] === "number") ? decimal2Hex(cluster[ClusterConstants.DFF80.Attributes.CurrentMode], 2).toUpperCase() as Mode : null); // TODO: remove toUpperCase()
	const [setpointValue, setSetpointValue] = useDynamicUpdateState((typeof cluster?.[ClusterConstants.DFF80.Attributes.CurrentSetpoint] === "number") ? roundToPrecision(cluster[ClusterConstants.DFF80.Attributes.CurrentSetpoint] / 100) : null);
	const [powerMode, setPowerMode] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF80.Attributes.PowerMode] ?? null);
	const [childLockToggle, setChildLockToggle] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF80.Attributes.ChildLockStatus] ?? null);
	const [shakeToggle, setShakeToggle] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF80.Attributes.ShakeStatus] ?? null);
	const [shakeLevel, setShakeLevel] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF80.Attributes.ShakeLevel] ?? null);
	const [fanSpeedValue, setFanSpeedValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF80.Attributes.FanSpeed] ?? null);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const handlePowerModeChange = (powerModeValue: PowerMode) => {
		if (powerModeValue === powerMode) {
			return;
		}

		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.DFF80.Attributes.PowerMode,
				datatype: Constants.DataType.UInt8Bit,
				value: decimal2Hex(powerModeValue, 2),
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"FF80">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setPowerMode(powerModeValue);
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleFanSpeedChangeCommited = (_event: Event | SyntheticEvent, value: number | Array<number>) => {
		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.DFF80.Attributes.FanSpeed,
				datatype: Constants.DataType.UInt8Bit,
				value: decimal2Hex(value as FanSpeed, 2),
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"FF80">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setFanSpeedValue(value as FanSpeed);
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleChildLockToggle = (isInputChecked: boolean) => {
		if (isInputChecked === childLockToggle) {
			return;
		}

		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.DFF80.Attributes.ChildLockStatus,
				datatype: Constants.DataType.Boolean,
				value: isInputChecked,
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"FF80">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setChildLockToggle(isInputChecked);
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleShakeToggle = (isInputChecked: boolean) => {
		if (isInputChecked === shakeToggle) {
			return;
		}

		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.DFF80.Attributes.ShakeStatus,
				datatype: Constants.DataType.Boolean,
				value: isInputChecked,
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"FF80">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShakeToggle(isInputChecked);
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleShakeLevelChange = (level: ShakeLevel) => {
		if (level === shakeLevel) {
			return;
		}

		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.DFF80.Attributes.ShakeLevel,
				datatype: Constants.DataType.Enum8Bit,
				value: level,
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"FF80">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShakeLevel(level);
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleModeClick = (mode: Mode) => {
		if (mode === modeValue) {
			setShowModeDialog(false);
		} else {
			const cmd = {
				action: "sendActionCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: ClusterConstants.DFF80.CmdIds.SetMode,
				value: mode,
			} as const satisfies CmdSendActionCmd;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setModeValue(mode);
					setShowGenericErrorMsg(undefined);
				} else {
					setShowGenericErrorMsg(Date.now());
				}
				setShowModeDialog(false);
			});
		}
	};

	const handleSetpointChange = (_e: null, setpoint: number) => {
		if (setpoint === setpointValue) {
			setShowSetpointDialog(false);
		} else {
			const cmd = {
				action: "sendActionCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: ClusterConstants.DFF80.CmdIds.SetSetPoint,
				value: decimal2Hex(Math.round(setpoint * 100), 4),
			} as const satisfies CmdSendActionCmd;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setSetpointValue(roundToPrecision(setpoint));
					setShowGenericErrorMsg(undefined);
				} else {
					setShowGenericErrorMsg(Date.now());
				}
				setShowSetpointDialog(false);
			});
		}
	};

	const setpointOptions = getSetpointOptions(cluster, modeValue);
	const gatewayTempUnit = Gateway.selectedGatewayTemperature;
	const setpoint = (gatewayTempUnit === Constants.TempUnit.Fahrenheit) ? convertToF(setpointValue, setpointOptions.step) : roundToPrecision(setpointValue, setpointOptions.step);
	const selectedMode = Constants.ThermostatModes.find((thermostatMode) => (thermostatMode.value === modeValue));
	const showModeControlls = modeValue !== null && availableModes.length > 1;
	const showSetpointControlls = isSetpointControllable && setpointValue !== null;

	const modeDialog = showModeControlls ? (
		<DrawerDialog
			id="dlg-thermostat-mode-zwave"
			title={t("clusters.DFF80.modeDialogTitle")}
			open={showModeDialog}
			onClose={() => (setShowModeDialog(false))}
			drawerActions={null}
			dialogActions={<Button className="btn-dlg-action-cancel" color="inherit" onClick={() => (setShowModeDialog(false))}>{t("dialog.cancel")}</Button>}
		>
			<List disablePadding={true}>
				{availableModes.map((availableMode) => (
					<ListItemButton
						className="li-thermostat-mode"
						data-thermostat-mode={availableMode.value}
						key={availableMode.value}
						sx={(availableMode.value === modeValue) ? { color: "primary.main" } : {}}
						onClick={() => (handleModeClick(availableMode.value))}
					>
						<ListItemIcon><Svg src={availableMode.image} /></ListItemIcon>
						<ListItemText primary={t(`clusters.DFF80.modes.${availableMode.l10n}`)} />
					</ListItemButton>
				))}
			</List>
		</DrawerDialog>
	) : null;

	if (props.showFull) {
		const entries: Array<ReactNode> = [];

		if (showModeControlls) {
			entries.push(
				<>
					<ListItemButton onClick={() => (setShowModeDialog(true))}>
						<ListItemIcon><Svg src={props.deviceType.entries[ClusterConstants.DFF80.Attributes.CurrentMode].getIcon()} /></ListItemIcon>
						<ListItemText primary={t("clusters.DFF80.mode")} />
						{selectedMode &&
							<ListItemContainer style={{ color: theme.palette.primary.main }}>
								<Svg src={selectedMode.image} style={{ alignSelf: "center", marginRight: "16px" }} />
								<ListItemText primary={t(`clusters.DFF80.modes.${selectedMode.l10n}`)} />
							</ListItemContainer>
						}
					</ListItemButton>
					{modeDialog}
				</>
			);
		}
		if (showSetpointControlls) {
			const handleDeInCreaseSetpointClick = (isIncrement: boolean) => {
				const newSetpoint = isIncrement ? Math.floor(setpointValue) + 1 : Math.ceil(setpointValue) - 1;
				handleSetpointChange(null, newSetpoint);
			};

			entries.push(
				<>
					<ListItem sx={{ padding: "0 4px 0 16px" }}>
						<ListItemText primary={t("clusters.DFF80.setpoint")} />
						<ListItemContainer>
							<IconButton
								disabled={!selectedMode || selectedMode.setpointDisabled || setpoint <= setpointOptions.min}
								onClick={() => (handleDeInCreaseSetpointClick(false))}
							>
								<icons.RemoveCircle />
							</IconButton>
							<Button
								disabled={!selectedMode || selectedMode.setpointDisabled}
								onClick={() => (setShowSetpointDialog(true))}
								sx={{ minWidth: "70px" }}
							>
								<UnitDisplay
									values={[setpoint]}
									digits={props.deviceType.entries[ClusterConstants.DFF80.Attributes.CurrentSetpoint].getDigits()}
									unit={props.deviceType.entries[ClusterConstants.DFF80.Attributes.CurrentSetpoint].getUnit()}
									style={{ color: (!selectedMode || selectedMode.setpointDisabled) ? theme.palette.text.disabled : theme.palette.primary.main }}
								/>
							</Button>
							<IconButton
								disabled={!selectedMode || selectedMode.setpointDisabled || setpoint >= setpointOptions.max}
								onClick={() => (handleDeInCreaseSetpointClick(true))}
							>
								<icons.AddCircle />
							</IconButton>
						</ListItemContainer>
					</ListItem>
					<TemperaturePicker
						title={t("clusters.DFF80.setpointDialogTitle")}
						open={showSetpointDialog}
						value={setpointValue}
						min={setpointOptions.min}
						max={setpointOptions.max}
						step={setpointOptions.step}
						unit={gatewayTempUnit}
						okText={t("general.set")}
						onChange={handleSetpointChange}
						onCancel={() => (setShowSetpointDialog(false))}
					/>
				</>
			);
		}
		if (powerMode !== null) {
			entries.push(
				<ListItem sx={{ padding: "8px 16px" }}>
					<ListItemText primary={t("clusters.DFF80.powerMode")} />
					<ListItemContainer>
						<ButtonGroup variant="contained">
							<Button
								color={(powerMode === PowerModes.High) ? "primary" : "neutral"}
								sx={{ width: "88px", ...(powerMode === PowerModes.High) ? {} : { backgroundColor: "common.white" } }}
								onClick={() => (handlePowerModeChange(PowerModes.High))}
							>
								{t("clusters.DFF80.powerModes.high")}
							</Button>
							<Button
								color={(powerMode === PowerModes.Low) ? "primary" : "neutral"}
								sx={{ width: "88px", ...(powerMode === PowerModes.Low) ? {} : { backgroundColor: "common.white" } }}
								onClick={() => (handlePowerModeChange(PowerModes.Low))}
							>
								{t("clusters.DFF80.powerModes.low")}
							</Button>
						</ButtonGroup>
					</ListItemContainer>
				</ListItem>
			);
		}
		if (childLockToggle !== null) {
			entries.push(
				<ListItem>
					<ListItemText primary={t("clusters.DFF80.childLock")} />
					<ListItemContainer><CustomSwitch checked={childLockToggle} onChange={handleChildLockToggle} /></ListItemContainer>
				</ListItem>
			);
		}
		if (shakeToggle !== null) {
			entries.push(
				<ListItem>
					<ListItemText primary={t("clusters.DFF80.shake")} />
					<ListItemContainer><CustomSwitch checked={shakeToggle} onChange={handleShakeToggle} /></ListItemContainer>
				</ListItem>
			);
		}
		if (shakeLevel !== null) {
			entries.push(
				<ListItem>
					<ListItemText primary={t("clusters.DFF80.level")} />
					<ListItemContainer>
						{Constants.AvailableHeaterLevel.map((level) => (
							<Button
								key={level}
								variant="contained"
								color={(shakeLevel === level) ? "primary" : "neutral"}
								disableRipple={shakeLevel === level}
								disableFocusRipple={shakeLevel === level}
								onClick={() => (handleShakeLevelChange(level))}
								sx={{ marginLeft: "10px", cursor: (shakeLevel === level) ? "default" : "pointer" }}
							>
								{level}
							</Button>
						))}
					</ListItemContainer>
				</ListItem>
			);
		}
		if (fanSpeedValue !== null) {
			entries.push(
				<ListItem sx={{ gap: 2 }}>
					<ListItemText primary={t("clusters.DFF80.fanSpeed")} />
					<ListItemContainer style={{ width: "50%" }}>
						<Slider
							min={FAN_SPEED_MARKS[0].value}
							max={FAN_SPEED_MARKS[5].value}
							step={null}
							marks={FAN_SPEED_MARKS}
							value={fanSpeedValue}
							onChange={(_event, value) => (setFanSpeedValue(value as FanSpeed))}
							onChangeCommitted={handleFanSpeedChangeCommited}
						/>
					</ListItemContainer>
				</ListItem>
			);
		}
		return entries.map((entry, index) => (
			<Fragment key={index}>
				{(index > 0) && <Divider />}
				{entry}
			</Fragment>
		));
	}

	return (
		<>
			{(showModeControlls && (!showSetpointControlls || !isSmallScreen)) &&
				<>
					<Button className="button-FF80-mode" onClick={() => (setShowModeDialog(true))}>
						{selectedMode ? t(`clusters.DFF80.modes.${selectedMode.l10n}`) : ""}
					</Button>
					{modeDialog}
				</>
			}
			{showSetpointControlls &&
				<>
					<Button
						className="button-FF80-setpoint"
						disabled={!selectedMode || selectedMode.setpointDisabled}
						onClick={() => (setShowSetpointDialog(true))}
					>
						<UnitDisplay
							values={[setpoint]}
							digits={props.deviceType.entries[ClusterConstants.DFF80.Attributes.CurrentSetpoint].getDigits()}
							unit={props.deviceType.entries[ClusterConstants.DFF80.Attributes.CurrentSetpoint].getUnit()}
							style={{ color: (!selectedMode || selectedMode.setpointDisabled) ? theme.palette.text.disabled : theme.palette.primary.main }}
						/>
					</Button>
					<TemperaturePicker
						title={t("clusters.DFF80.setpointDialogTitle")}
						open={showSetpointDialog}
						value={setpointValue}
						min={setpointOptions.min}
						max={setpointOptions.max}
						step={setpointOptions.step}
						unit={gatewayTempUnit}
						okText={t("general.set")}
						onChange={handleSetpointChange}
						onCancel={() => (setShowSetpointDialog(false))}
					/>
				</>
			}
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

DFF80.defaultProps = {
	showFull: false,
};

DFF80.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		// getValue: PropTypes.func.isRequired, // TODO
		entries: PropTypes.shape({
			[ClusterConstants.DFF80.Attributes.CurrentMode]: PropTypes.shape({
				getIcon: PropTypes.func.isRequired,
			}).isRequired,
			[ClusterConstants.DFF80.Attributes.CurrentSetpoint]: PropTypes.shape({
				getUnit: PropTypes.func.isRequired,
				getDigits: PropTypes.func.isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
	showFull: PropTypes.bool,
};

export default DFF80;
